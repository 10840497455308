.circle-overflow {
  position: absolute;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
}

.circle-wrapper {
  display: block;
  height: 275px;
  margin: 0;
  opacity: .45;
  position: absolute;
  top: 50%;
  width: 275px;
}

.circle-wrapper.right {
  right: 0;
  transform: translate(50%,-50%)
}

.circle-wrapper.left {
  left: 0;
  transform: translate(-50%,-50%)
}

.circle-wrapper>div {
  border: 1px solid #79cccc;
  border-radius: 50%;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  transition: all 2s linear;
  width: 50%
}

.circle-wrapper .circle0 {
  border: 0;
  height: 5px;
  width: 5px
}

.circle-wrapper.visible .circle2 {
  height: 63%;
  width: 63%
}

.circle-wrapper.visible .circle3 {
  height: 75%;
  width: 75%
}

.circle-wrapper.visible .circle4 {
  height: 88%;
  width: 88%
}

.circle-wrapper.visible .circle5 {
  height: 100%;
  width: 100%
}

.circle-wrapper .circle3,.circle-wrapper .circle4 {
  border-color: #5593b3
}

.circle-wrapper .circle5 {
  border-color: #2e5597
}

@media(min-width: 1025px) {
  .circle-wrapper {
    height: 552px;
    width: 552px
  }

  .circle-wrapper.small {
    height: 407px;
    width: 407px
  }
}

@media(min-width: 1920px) {
  .circle-wrapper.left {
    left:0!important
  }

  .circle-wrapper.right {
    right: 0!important
  }
}
