.hamburger {
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 25px;
  transition: all 2s linear;
  .line {
    background: #fff;
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    &-1 {
      top: 0;
      &-active {
        top: 50%;
        transform: rotate(45deg);
      }
    }
    &-2 {
      top: calc(50% - 1px);
      &-active {
        opacity: 0;
      }
    }
    &-3 {
      bottom: 0;
      &-active {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}

.navbar-dropdown-basic {
  list-style: none;
  background: $body-background;
  margin-top: 20px;
  padding: 20px;
  li {
    position: relative;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    a:hover {
      color: $primary;
    }
  }
  @include respond-to('large'){
    left: 15px;
    position: absolute;
    top: 100%;
    width: 200px;
    margin-top: 0px;
    background: $dark-text-color;
  }
}

.navbar-dropdown-sections {
  list-style: none;
  background: $body-background;
  margin-top: 20px;
  padding: 20px 20px 0 20px;
  &-item {
    position: relative;
    padding-bottom: 20px;
    p {
      margin: 0;
    }
    &-subdropdown {
      list-style: none;
      margin: 20px 0 0 0;
      padding: 0;
      li {
        margin-bottom: 2px;
        a {
          display: flex;
          align-items: center;
          background-color: rgba(119, 119, 122,1.0);
          padding: 15px;
          &:hover {
            color: $primary;
          }
          img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
          }
        }
      }
    }
    &:first-child {
      li {
        a {
          background-color: rgba(6, 54, 70,1.0);
        }
      }
    }
    &:last-child {
      li {
        a {
          background-color: rgba(13, 62, 108,1.0);
        }
      }
    }
  }
  @include respond-to('large'){
    position: absolute;
    left: 0;
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 15px 0 35px;
    top: 100%;
    margin-top: 0;
    background: $body-background;
    &-item {
      padding: 0 8px;
      text-align: center;
      width: 300px;
      p {
        color: #79cccc;
      }
    }
  }
}
