$breakpoints: (
  'small': (min-width: 600px),
  'medium': (min-width: 768px),
  'large': (min-width: 1025px),
  'huge': (min-width: 1200px),
);

$background-gray: #fff;
$primary: #79cccc;
$secondary: #2e5597;
$tertiary: #459bfa;
$light-text-color: #e6e6e6;
$dark-text-color: #414141;
$body-background: #292929;
$header-background: #343434;
$primary_font: Poppins, sans-serif;
$secondary_font: Aeonik, sans-serif;

$max-width: 1200px;
$min-header-height: 76.25px;
$max-header-height: 96px;
$footer-height: 546.39px;
