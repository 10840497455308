// Used in DropdownAnimate
@keyframes growDown {
  0% {
    transform: scaleY(0)
  }
  100% {
    transform: scaleY(1)
  }
}

@keyframes growUp {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
    opacity: 0; 
    height: 0px;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
