button {
  cursor: pointer;
}

.button-gradient {
  background: linear-gradient(95.15deg,#2e5597 7.68%,#79cccc 76.69%);
  border: 2px solid transparent;
  border-left: 0;
  border-right: 0;
  margin: 0 2px;
  color: #fff;
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 18px 38px !important;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background: transparent;
    border: 2px solid $primary;
    color: white;
    margin: 0;
  }
}

.button-primary {
  background: $primary;
  border: 2px solid $primary;
  color: $dark-text-color;
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 38px !important;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background: $dark-text-color;
    color: white;
  }
  &:disabled {
    background: $dark-text-color;
    color: $header-background;
    border: 2px solid $header-background;
  }
}

.button-primary-r {
  border: 2px solid $primary;
  background: $dark-text-color;
  color: white;
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 38px !important;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    color: $dark-text-color;
    background: $primary;
  }
}

.button-highlight {
  background: #fdc743;
  border: 2px solid #fdc743;
  color: $dark-text-color;
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 38px !important;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background: $dark-text-color;;
    color: white;
  }
}

.button-secondary {
  background: $secondary;
  border: 2px solid $secondary;
  color: white;
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 38px !important;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background: white;
    color: $secondary;
  }
}

.button-red {
  background: #EF493E;
  border: 2px solid #EF493E;
  color: white;
  border-radius: 32px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 38px !important;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    background: white;
    color: #EF493E;
  }
}
