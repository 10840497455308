input,option,select,textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

textarea {
  height: 120px;
  overflow: auto;
  resize: none;
  vertical-align: top;
}

form p {
  margin: 10px 0 5px 0;
}

form fieldset {
  max-width: 100%;
}

form input[type=date],form input[type=datetime-local],form input[type=datetime],form input[type=email],form input[type=file],form input[type=month],form input[type=number],form input[type=password],form input[type=search],form input[type=tel],form input[type=text],form input[type=time],form input[type=url],form input[type=week],form select,form textarea, select {
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  box-sizing:content-box;
  display: inline-block;
  padding: 10px 7px;
  width: calc(100% - 24px);
  margin-bottom: 10px;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.form-columns {
  display: flex;
  flex-direction: column;
  label {
    width: 100%;
    margin: 0;
  }
  @include respond-to('medium'){
    flex-direction: row;
  }
}

.calendar-confirmation-form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    label {
      margin-bottom: 10px;
    }
  }
  .addeventatc {
    margin-bottom: 10px;
    // padding: 12px;
  }
  
  button[type=submit] {
    margin: 0 20px 20px 20px;
  }
}

#radio-binary {
  &.disabled {
    color: gray;
    cursor: default;
    input {
      cursor: default;
    }
  }
  label {
    position: relative;
    margin-right: 30px;
    input {
      position: absolute;
      margin-left: 4px;
      height: 100%;
      cursor: pointer;
    }
  }
}
