.registrations {
  margin-bottom: 50px;
  &-content {
    // max-width: 700px;
    margin-bottom: 30px;
    width: 100%;
    @include respond-to('medium') {
      margin-right: 50px;
    }
  }
  h3 {
    margin: 0 0 10px 0;
  }
  h4 {
    margin: 25px 0 0 0;
  }
}

// Inline with button
.registration-error {
  color: rgb(224, 59, 59);
  margin: 0  0 0 20px;
}

.registration-panel {
  width: 325px;
  min-width: 325px;
  padding-bottom: 50px;
}

.prospect-registrations-access {
  display: flex;
  flex-direction: column;
}

.event-registrations {
  &-progress {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 40px);
    margin: -20px -20px 20px;
    border-bottom: 1px solid $primary; 
    strong {
      width: 33.33%;
      padding: 15px 0;
    }
    .active {
      background-color: $primary;
      color: $dark-text-color;
    }
  }
  &-programs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
      margin-bottom: 10px;
    }
    &-check {
      margin: -8px 0 18px 20px;
      color: $light-text-color;
      font-size: 14px;
      input {
        margin-right: 5px;
      }
    }
  }
  &-confirmation-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: orange;
    color: $dark-text-color;
    margin-bottom: 20px;
    padding: 10px;
    width: calc(100% + 20px);
    margin: -20px -20px 20px -20px;
    img {
      width: 200px;
    }
  }
}

.event-review-section {
  background-color: $body-background;
  margin-bottom: 10px;
  padding: 5px;
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    p {
      margin: 0;
    }
    aside {
      justify-self: flex-end;
    }
    button {
      cursor: pointer;
    }
    .edit-button {
      background-color: $secondary;
      color: white;
      border: 1px solid $secondary;
      margin-right: 5px;
      &:hover {
        background-color: white;
        color: $secondary;
        border: 1px solid white;
      }
    }
    .delete-button {
      background-color: #EF493E;
      color: $dark-text-color;
      cursor: pointer;
      border: 1px solid #EF493E;
      &:hover {
        background-color: $dark-text-color;
        color: #EF493E;
        border: 1px solid $dark-text-color;
      }
    }
  }
}

.stripe-wrap {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 40px;
  .stripe-card-element {
      width: 360px;
      padding: 15px 20px;
      background-image: linear-gradient(to top, #193F80, $primary, #476AA5);
      label {
          color: white;
          font-size: 16px;
      }
  }
  button {
      margin-left: 20px;
      width: 120px;
      height: 36px;
      border-radius: 5px;
      background: $secondary;
      color: white;
      font-size: 10px;
  }
}

.card-input {
  &-wrap {
      background-color: white;
      margin: 10px;
      padding: 10px;
      box-shadow: 0px 16px 40px -15px rgba(0,0,0,0.75);
      
  }
  &-postal {
      border: 0px;
      padding: 0;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      &::placeholder {
          color: rgb(106, 106, 106);
      }
      &:focus{
          outline: none;
      }
  }
}
