.hero-banner-section {
  margin-bottom: -230px;
  padding: 147px 0 300px;
  position: relative;
  z-index: -1;
}

.hero-banner-inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 900px;
  min-height: 300px;
  h2 {
    margin: 10px 0 0 0;
  }
  p {
    margin: 0;
  }
}

.hero-banner-img-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.hero-banner-img-wrapper .banner-overlay {
  background: linear-gradient(360deg, #292929 0%, rgba(0, 0, 0, 0) 31.9%);
}

.hero-banner-img-wrapper .banner-overlay {
  bottom: 0;
  content: "";
  height: 25%;
  left: 0;
  position: absolute;
  width: 100%;
}

.hero-banner-img-wrapper {
  img {
    display: inline-block;
    font-size: .583rem;
    height: 100%;
    max-width: 100%;
    word-break: normal;
  }
  img, svg {
    vertical-align: middle;
  }
}

.hero-banner-img {
  height: 100%;
  object-fit: cover;
  opacity: .7;
  width: 100%;
}

.hero-banner-img-wrapper .banner-overlay {
  bottom: 0;
  content: "";
  height: 25%;
  left: 0;
  position: absolute;
  width: 100%;
}

.hero-banner-section .circle-wrapper.right {
  transform: translateX(50%);
}
