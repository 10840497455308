.workbook-gate {
  display: flex;
  justify-content: center;
  align-items: center;
	height: 100vh;
	min-width: 100vw;
	z-index: 4;
	overflow: hidden;
  color: $dark-text-color;

	&::before {
		content: ' ';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('../../Assets/Coaching-Workbook.png');
		background-repeat: no-repeat, repeat;
		background-color: #cccccc;
		background-position: center;
		background-size: contain;
		z-index: 3;
		filter: blur(5px) grayscale(100);
	}

	header {
		margin-bottom: 15px;
		color: $secondary;
		font-size: 22px;
	}

	form {
		display: flex;
		flex-direction: column;
		max-width: 500px;
		padding: 50px;
		border-radius: 5px;
		background-color: white;
		z-index: 3;
		input,
		button {
			padding: 5px;
			margin: 5px;
		}

		label {
			text-align: left;
			font-size: 12px;
		}

		button {
			margin-top: 15px;
			background-color: $secondary;
			color: white;
			border: 2px solid $secondary;
			&:hover {
				background-color: white;
				color: $secondary;
			}
			&:disabled {
				background-color: gray;
				border: 2px solid gray;
				color: white;
			}
		}
	}
}
