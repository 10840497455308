body {
  line-height: 1.5;
  overflow-wrap: break-word;
  color: white;
  background-color: $body-background;
  font-family: $primary_font;
}

.App {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}
