.header {
  position: fixed;
  width: 100%;
  top: 0;
  padding: 20px 0;
  z-index: 3;
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  &-logo {
    width: 150px;
    img {
      vertical-align: middle;
      display: inline-block;
      height: auto;
      max-width: 100%;
      overflow-clip-margin: content-box;
      overflow: clip;
    }
  }
  &-navbar {
    display: block;
    background: $header-background;
    height: 100vh;
    left: 0;
    overflow-y: auto;
    padding: 20px 20px 125px;
    position: absolute;
    top: 100%;
    width: calc(100% - 40px);
    &-menu {
      position: relative;
      list-style: none;
      margin: 0;
      padding-left: 0;
      a {
        color: white;
        text-decoration: none;
        cursor: pointer;
      }
      &-item {
        display: list-item;
        text-align: -webkit-match-parent;
        height: 100%;
        padding-bottom: 20px;
        position: relative;
        font-size: 16px;
        font-family: $primary_font;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  @include respond-to('large'){
    padding: 0px;
    &-wrap {
      flex-wrap: nowrap;
      padding: 0 20px;
      max-width: $max-width;
      height: fit-content;
      margin: 0 auto;
    }
    &-logo {
      width: 182px;
    }
    &-navbar {
      position: unset;
      display: flex;
      height: 100%;
      padding: 0px;
      width: auto;
      overflow-y: visible;
      background: inherit;
      &-menu {
        display: flex;
        margin: 0;
        padding-left: 0;
        a {
          color: white;
          text-decoration: none;
          cursor: pointer;
        }
        &-item {
          display: flex;
          text-align: -webkit-match-parent;
          padding: 0 7px;
          position: relative;
          &-text {
            padding: 37px 0;
            &:after {
              background: $primary;
              bottom: 0;
              content: "";
              height: 5px;
              left: 0;
              position: absolute;
              transition: width .15s linear;
              width: 0;
            }
            &:hover {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.header-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  @include respond-to('large'){
    margin-left: 10px;
  }
}

.header-arrow-trigger {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  width: 48px;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
}

// remove dropdown elements above mobile
@include respond-to('large'){
  .header-arrow-trigger {
    display: none;
  }
  .header .hamburger {
    display: none;
  }
}
