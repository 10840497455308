#footer {
  background-color: black;
  color: white;
  padding: 70px 0 0;
  width: 100%;
  justify-self: flex-end;

  a {
    color: inherit;
    text-decoration: none;
    transition: color .15s linear;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }

  ul {
    list-style-type: none;
  }

  .footer-wrap {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .footer-top {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    &-logo {
      height: auto;
      img {
        width: 225px;
        max-width: 100%;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      .contact-item {
        display: flex;
        align-items: center;
        padding-right: 0;
        margin-bottom: 20px;
        &-icon {
          align-items: center;
          border: 1px solid $primary;
          border-radius: 50%;
          display: flex;
          height: 39px;
          justify-content: center;
          width: 39px;
        }
        &-text {
          padding-left: 10px;
          width: calc(100% - 39px);
          font-size: 16px;
          font-weight: 400;
        }
      }
      @include respond-to('medium'){
        display: flex;
        flex-direction: row;
        .contact-item {
          padding-right: 20px;
          margin-bottom: 0px;
        }
      }
      @include respond-to('large'){
        margin-top: 0px;
        .contact-item {
          padding-left: 30px;
          padding-right: 0;
        }
      }
    }
    @include respond-to('large'){
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .footer-middle {
    margin-bottom: 30px;
  }

  .footer-navigation {
    margin: 0;
    padding: 0;
    &-section {
      padding: 15px 0px;
      &-header {
        font-weight: bold;
        font-size: 20px;
      }
      &-list {
        margin: 0;
        padding: 0;
        &-item {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
    @include respond-to('medium'){
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      &-section {
        padding: 0 5px;
        &:nth-child(2){
          margin-top: 30px;
        }
      }
    }
  }

  .footer-social-icons {
    margin-top: 15px;
    ul {
      margin: 0 -8px;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      li {
        margin: 0 8px;
        a {
          align-items: center;
          background: $primary;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          padding: 6px;
          transition: background .15s linear;
          img {
            height: 16px;
            width: 16px;
          }
          &:hover {
            background: $secondary;
          }
        }
      }
      @include respond-to('medium'){
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .footer-bottom {
    text-align: center;
    border-top: 1px solid hsla(0,0%,100%,.4);
    font-size: 14px;
    padding: 25px 0 35px;
    p {
      margin: 0;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      li {
        margin: 5px 10px;
      }
    }
    @include respond-to('large'){
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      ul {
        margin: 0;
      }
    }
  }
}
