.main-modal {
	display: flex;
	justify-content: center;
  align-items: center;
	width: 45%;
	background-color: white;
	border: 1px solid $secondary;
	outline: none;
	color: black;
	max-height: 80vh;
  h3 {
    color: black;
		margin-top: 0
  }
	&-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #00000030;
		z-index: 2;
		backdrop-filter: blur(3px);
	}
	&-body {
		width: 75%;
    margin-bottom: 10px;
    button {
      margin: 10px auto;
    }
	}
}

.close-icon-wrapper{
  display: flex;
  justify-content: flex-end;
  width: 112%;
}

.close-icon{
  margin-top: 4%;
  cursor: pointer;
}
