@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../../fonts/Poppins-Regular.ttf') format('truetype'),
}
@font-face {
  font-family: 'Aeonik';
  src: local('Aeonik'),
    url('../../fonts/DMSans-Regular.ttf') format('truetype'),
}

a {
  cursor: pointer;
  text-decoration: none;
  transition: color .15s linear;
  color: #79cccc;
}

a:focus,a:hover {
  color: #459bfa;
}

a:active {
  color: #2e5597;
}

.h1,h1 {
  font-size: 50px
}

.light-font {
  font-weight: 400;
  color: $light-text-color;
}

.h1,.h2,h1,h2 {
  color: #fff;
  font-family: Aeonik, serif;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  text-transform: none
}

.h2,h2 {
  font-size: 40px
}

.h3,h3 {
  font-size: 30px
}

.h3,.h4,h3,h4 {
  color: #fff;
  font-family: Aeonik,serif;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  text-transform: none
}

.h4,h4 {
  font-size: 24px
}

.h5,h5 {
  font-family: Aeonik,serif;
  font-size: 16px;
  font-weight: 700
}

.h5,.h6,h5,h6 {
  color: #fff;
  font-style: normal;
  text-decoration: none;
  text-transform: none
}

.h6,h6 {
  font-family: Poppins,sans-serif;
  font-size: 14px;
  font-weight: 600
}

@media(max-width: 1024px) and (min-width:768px) {
  .h1,h1 {
    font-size:42px
  }

  .h2,h2 {
    font-size: 32px
  }

  .h3,h3 {
    font-size: 24px
  }
}

@media(max-width: 1280px) and (min-width:1025px) {
  .h2,h2 {
    font-size:36px
  }
}

@media(max-width: 1024px) {
  .h4,h4 {
    font-size: 20px
  }
}

@media(max-width: 767px) {
  .h1,h1 {
    font-size:32px
  }

  .h2,h2 {
    font-size: 28px
  }

  .h3,h3 {
    font-size: 22px
  }
}
